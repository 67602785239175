import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import AppInfo from "../../components/app-info"
import Icon from "../../components/images/icons/image_icon_bowling3d"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <AppInfo
      Icon={Icon}
      isRadius
      appName="リアルボウリング3D"
      category="スポーツ"
      price="無料"
      release="2016/01/25"
      language="日本語・英語・中国語・韓国語"
      lastUpdate="2020/02/22"
      version="2.14.1"
    />
  </Layout>
)

export default IndexPage
